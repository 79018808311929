@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styles_container__30FsG {
  font-size: 10px;
  color: #FFFFFF !important; }
  .styles_container__30FsG select {
    color: #FFF;
    font-family: 'Roboto-Black';
    font-size: 12px;
    letter-spacing: -0.07px; }
  .styles_container__30FsG select:focus {
    background-color: #308BFD; }
  .styles_container__30FsG .styles_icon__2wPqo {
    color: #FFF !important; }

.styles_container__30FsG:before {
  border-bottom: 0px !important; }

.styles_container__1m8z6 {
  background: #ffffff;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  padding: 15px 20px; }

.styles_title__2tHK8 {
  font-family: "Roboto";
  font-size: 15px;
  color: #4f5868;
  letter-spacing: 0;
  height: 42px;
  margin-top: 0px; }

.styles_statContainer__2n4Zz {
  margin-top: 20px; }

.styles_summaryStat__3LYX0 {
  font-family: "Roboto";
  font-size: 48px;
  color: #308bfd;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0px;
  margin-bottom: 20px; }

.styles_summaryLabel__3yrB7 {
  font-family: "Roboto";
  color: #9b9b9b;
  letter-spacing: 0; }

.styles_durationStyle__2q5l6 {
  font-family: "Roboto";
  font-size: 16px;
  color: #cfedb5;
  margin-right: 5px;
  letter-spacing: 0; }

.styles_buttonStyle__3ubqS {
  background-color: rgba(121, 130, 139, 0.1) !important;
  border: unset !important;
  border-radius: 16px !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  color: #99a3ae !important;
  padding: 0px !important;
  text-transform: unset !important; }

.styles_negativeChange__2cdUQ {
  color: #ef7771; }

.configureStyles_container__2-KO- {
  padding: 10px;
  max-height: 400px;
  width: 300px; }

.configureStyles_checkboxContainer__13dkl {
  display: block;
  width: 100%;
  padding: 0px 10px;
  max-height: 245px;
  overflow: scroll; }

.configureStyles_actionBtnContainer__1QxTw {
  text-align: right;
  background: #FFFFFF;
  box-shadow: 0 -1px 0 0 #EEEEEE;
  padding: 15px; }

.styles_container__2KhW1 {
  background-color: #F4F8F9;
  flex: 1 1;
  padding-bottom: 60px; }

.styles_overviewContainer__-z9y9 {
  padding: 25px 30px; }

.styles_buttonContainer__c5ryk {
  text-align: right; }

.styles_notificationContainer__ivB6S {
  background-color: #FFF;
  padding: 35px;
  text-align: right; }

.styles_titleStyle__1tlzH {
  font-size: 38px;
  color: #2A3C41;
  letter-spacing: -1.09px;
  margin: 0px; }

.styles_configBtn__72uWh {
  background-color: #FFFFFF !important;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03) !important;
  border-radius: 3px !important;
  font-family: 'Roboto-Black' !important;
  color: #3A4451 !important;
  letter-spacing: 0;
  text-transform: capitalize; }

.styles_iconStyle__lBHz3 {
  margin-right: 8px; }

.styles_notifHeaderContainer__2KGye {
  display: flex;
  align-items: flex-end; }

.styles_notifIconStyle__sApm0 {
  color: #FFFFFF;
  font-size: 26px; }

.styles_notifTextStyle__25kK6 {
  font-family: 'Roboto-Black';
  font-size: 16px;
  color: #666E81;
  letter-spacing: 0;
  margin-left: 10px; }

.styles_dismissViewBtn__1jNnQ {
  font-size: 10px !important;
  color: #8FBCFF !important; }

.styles_notifTitle__1kRKr {
  font-family: 'Roboto-Black';
  font-size: 14px;
  color: #444E5C;
  letter-spacing: -0.1px;
  line-height: 19px;
  margin: 0px; }

.styles_notifTimeStamp__5jI9o {
  font-family: 'Roboto-Black';
  font-size: 12px;
  color: #A2A2A2;
  line-height: 36px; }

.styles_divider__3VSw8 {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  background-color: rgba(77, 161, 255, 0.8) !important;
  height: 6px !important;
  box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.05);
  border-radius: 2px; }

.styles_notifContainer__1wmRU {
  margin-top: 50px; }

.styles_container__103ut {
  background-color: #FFF !important;
  height: 101px;
  box-shadow: 0 5px 5px rgba(231, 233, 234, 0.5) !important;
  overflow: scroll; }

.styles_titleText__356Zl {
  width: 100%; }

.styles_nameText__3bBeP {
  font-size: 13px;
  white-space: nowrap;
  color: #7E8797;
  font-weight: 300; }

.styles_avatar__3oVu_ {
  margin-left: 15px; }

.styles_container__3CPOD {
  width: 100px;
  border-right: 1px solid rgba(197, 197, 197, 0.7);
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  padding-top: 30px; }
  .styles_container__3CPOD .styles_customLink__MTL6r {
    text-decoration: none;
    width: 100%; }

.styles_logoStyle__2aHr_ {
  margin-bottom: 100px !important; }

.styles_activeBtnStyle__VPIBu {
  border-left: 5px solid #308BFD !important;
  border-radius: 0px !important; }

.styles_btnStyle__XqdY0 {
  padding: 10px 10px !important;
  display: block !important; }

.styles_iconStyle__3LEhk {
  display: block;
  margin: 0 auto; }

label {
  font-size: 12px;
  color: #308BFD;
  font-weight: 500;
  display: block;
  font-family: 'Roboto-Medium';
  letter-spacing: 0;
  text-transform: none;
  text-transform: initial;
  line-height: 1;
  text-align: center;
  margin-top: 5px; }

/* @import url('https://use.fontawesome.com/releases/v5.10.1/css/all.css'); */
/* @import url('https://use.fontawesome.com/releases/v5.10.1/css/v4-shims.css'); */

body {
  font-family: "Roboto", sans-serif;
  color: #308bfd;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #f4f8f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

