.container {
  padding: 10px;
  max-height: 400px;
  width: 300px;
}

.checkboxContainer {
  display: block;
  width: 100%;
  padding: 0px 10px;
  max-height: 245px;
  overflow: scroll;
}

.actionBtnContainer {
  text-align: right;
  background: #FFFFFF;
  box-shadow: 0 -1px 0 0 #EEEEEE;
  padding: 15px;
}