.container {
    width: 100px;
    border-right: 1px solid rgba(197,197,197,0.7);
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    .customLink {
        text-decoration: none;
        width: 100%;
    }
}

.logoStyle {
    margin-bottom: 100px !important;
}

.activeBtnStyle {
    border-left: 5px solid #308BFD !important;
    border-radius: 0px !important;
    // box-shadow: 2px 3px 11px 0 rgba(1,172,253,0.74);
}

.btnStyle {
    padding: 10px 10px !important;
    display: block !important;
}

.iconStyle {
    display: block;
    margin: 0 auto;
}

label {
    font-size: 12px;
    color: #308BFD;
    font-weight: 500;
    display: block;
    font-family: 'Roboto-Medium';
    letter-spacing: 0;
    text-transform: initial;
    line-height: 1;
    text-align: center;
    margin-top: 5px;
}