@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700,900&display=swap");
/* @import url('https://use.fontawesome.com/releases/v5.10.1/css/all.css'); */
/* @import url('https://use.fontawesome.com/releases/v5.10.1/css/v4-shims.css'); */

body {
  font-family: "Roboto", sans-serif;
  color: #308bfd;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #f4f8f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
